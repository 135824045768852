import axios from 'axios'
import { Toast } from 'vant'
import router from '@/router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json'
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    console.log(res)
    if (res.code === 0) {
      return res
    } else if (+res.code === 503) {
      router.replace({ name: 'upgrade' })
    } else {
      // Toast.fail(res.message || 'error')
      // return Promise.reject(new Error(res.message || 'Error'))
        return res
    }
  },
  error => {
    Toast.fail('服务器异常，请稍后重试')
    console.log(error.response)
    return Promise.reject(error)
  }
)

export default service
